


export class BrowserAppEvaluationToolInfo {

  public version = '2024.5.13.3';

  constructor() {
  }

}
